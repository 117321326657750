<template>
    <div>
        <h2>Plakát / emléklap</h2>
        <image-galery subfolder="archive2019"></image-galery>
		<h2>Ötpróba</h2>
        <image-galery subfolder="trials2019"></image-galery>
    </div>    
</template>

<script>
  import ImageGaleryComponent from './ImageGaleryComponent.vue'

  export default {
    name: 'archive-2019',
    components: {
        'image-galery': ImageGaleryComponent
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h2:first-child {
        margin-top: 20px;
    }
</style>
